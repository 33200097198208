import {useEffect, useState} from 'react';
import {Modal, ActionIcon, Tooltip, Code, Select} from '@mantine/core';
import {Plus} from "tabler-icons-react";
import {useClipboard, useDisclosure} from '@mantine/hooks';
import styles from "../Block/Block.module.scss";
import {CopyIcon} from "../Block/CopyIcon";
import {quote} from "shell-quote";
import {ImTarget} from "react-icons/im";

function _getCommand(serverLicense, updateServerHostname, command, targetOS, proxyUrl = null, proxyUseAuth = null, proxyUser = null, proxyPass = null, insecure = false) {
    let curlArray = ["curl"]

    if (insecure) {
        curlArray.push("-k")
    }

    if (proxyUrl) {
        if (proxyUseAuth && proxyUser) {
            curlArray.push("--proxy-anyauth")
            curlArray.push("--proxy-user")

            let auth = proxyUser
            if (proxyPass) {
                auth += `:${proxyPass}`
            }

            curlArray.push(auth)
        }

        curlArray.push("-x")
        curlArray.push(proxyUrl)
    }

    curlArray.push("-fs")
    curlArray.push(`https://${updateServerHostname}/p78.php?token=${serverLicense}&action=${command}`)

    let curlCommand = quote(curlArray)
    let execCommand = quote(["/root/project78/p78", updateServerHostname, command, targetOS])

    return `sudo -s -- sh -c '[ ! -f /root/project78/p78 ] && { ${curlCommand} | tar -xzf - -C /root || echo "Server not reachable"; }; ${execCommand}'`
}

export function CopyField({data}) {
    const clipboard = useClipboard();

    return <>
        <Tooltip
            className={styles.copy}
            label={clipboard.copied ? 'Copied' : 'Copy Code'}
            position="left"
            placement="center"
            transition="fade"
            withArrow
            arrowSize={4}
            gutter={8}
            color={clipboard.copied ? 'teal' : undefined}
        >
            <ActionIcon
                aria-label={clipboard.copied ? 'Copied' : 'Copy Code'}
                onClick={() => clipboard.copy(data)}
            >
                <CopyIcon copied={clipboard.copied}/>
            </ActionIcon>
        </Tooltip>
        <Code className={styles.code} block style={{maxWidth: "1000px"}}>
            {data}
        </Code>
    </>
}


export function NewServerButton({license}) {
    const [opened, {open, close}] = useDisclosure(false);
    const {cloudEnabled, serverLicense, updateServerHostname} = license
    const [targetOs, setTargetOs] = useState("rhel8")

    let realHostname = cloudEnabled ? updateServerHostname : window.location.origin.replace(/^https?:\/\//i, "")
    let insecure = !cloudEnabled

    return (
        <div>
            <ActionIcon onClick={open} h={"36px"} w={"36px"} color={"customGreen"} variant="outline">
                <Plus/>
            </ActionIcon>
            <Modal
                opened={opened}
                onClose={close}
                withCloseButton={true}
                title={"Upgrade a new server"}
            >
                <h2>1. Select Target Operating System</h2>
                <Select
                    icon={<ImTarget/>}
                    placeholder="Target OS"
                    value={targetOs}
                    onChange={setTargetOs}
                    data={[
                        {
                            value: 'rhel8',
                            label: 'RHEL 8'
                        },
                        {
                            value: "rhel8.6",
                            label: "RHEL 8.6",
                        },
                        {
                            value: "rhel8.8",
                            label: "RHEL 8.8",
                        },
                        {
                            value: "rocky8",
                            label: "Rocky 8",
                        },
                        {
                            value: "rocky8.6",
                            label: "Rocky 8.6",
                        },
                        {
                            value: "rocky8.8",
                            label: "Rocky 8.8",
                        }
                    ]}
                />
                <h2>2. Prepare Server</h2>
                <CopyField
                    data={_getCommand(
                        serverLicense || "SERVER_LICENSE_KEY", realHostname, "audit", targetOs, null, null, null, null, insecure
                    )}
                />
                <h2>3. Upgrade Server</h2>
                <CopyField
                    data={_getCommand(
                        serverLicense || "SERVER_LICENSE_KEY", realHostname, "run-noaudit", targetOs, null, null, null, null, insecure
                    )}
                />
            </Modal>
        </div>
    );
}