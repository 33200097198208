import { getBlock } from '../../modules/SimpleBlock/SimpleBlock'
import { useState, useEffect, forwardRef } from 'react';
import { MultiSelect, Box, CloseButton } from '@mantine/core';
import {GoSettings } from 'react-icons/go'

function Value({
    value,
    label,
    onRemove,
    classNames,
    ...others
}) {
	const [state, recipe,] = value.split("|")
	const [type, realValue] = state.split(",")

	let style = {}
	if(type === "2"){
		style = { color: "red" }
	}

    return (
      <div {...others}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            cursor: 'default',
            alignItems: 'center',
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]}`,
            paddingLeft: 10,
            borderRadius: 4,
          })}
        >
          <Box style={style}>
            {getBlock(realValue)} {recipe}
          </Box>
          <CloseButton
            onMouseDown={onRemove}
            variant="transparent"
            size={22}
            iconSize={14}
            tabIndex={-1}
          />
        </Box>
      </div>
    );
}
  
const Item = forwardRef(({ label, value, ...others }, ref) => {
	const [, realValue] = value.split("|")
	const [type, realRealValue] = realValue.split(",")

	let style = {}
	if(type === "2"){
		style = { color: "red" }
	}

    return (
		<div ref={ref} {...others}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box mr={10}>
					{getBlock(realRealValue)}
				</Box>
				<div style={style}>{label}</div>
			</Box>
		</div>
    );
});


const statuses = [
	{ value: '!!|1,red', label: 'Error', group: 'Status' },
	{ value: '!!|1,orange', label: 'Warning', group: 'Status' },
	{ value: '!!|1,green', label: 'Success', group: 'Status' },
	{ value: '!!|1,blinking', label: 'Starting', group: 'Status' },
	{ value: '!!|1,empty', label: 'None', group: 'Status' },
	{ value: '!!|2,red', label: 'Error', group: 'Negative Status' },
	{ value: '!!|2,orange', label: 'Warning', group: 'Negative Status' },
	{ value: '!!|2,green', label: 'Success', group: 'Negative Status' },
	{ value: '!!|2,blinking', label: 'Starting', group: 'Negative Status' },
	{ value: '!!|2,empty', label: 'None', group: 'Negative Status' },
]

export function StateFilter({authorization, filter, setFilter}){
  	const [latestRecipes, setLatestRecipes] = useState([])
  	const [innerFilter, setInnerFilter] = useState(filter)

  	let timer

	const _updateRecipes = async () => {
		setLatestRecipes(await fetch("/p78_api/recipes", {headers: {authorization}}).then(r => r.json()))
	}
	const updateRecipes = () => {
		timer = setInterval(_updateRecipes, 10000)
	}

	useEffect(() => {
		_updateRecipes().then(()=>{})
	}, [])
  
	useEffect(() => {
		updateRecipes()
		return () => clearInterval(timer)
	}, [latestRecipes])

	let [floatingRecipe, setFloatingRecipe] = useState(null)

	let foundFloatingStatus = null
	for(let value of innerFilter){
		if(value.startsWith("??|") && !floatingRecipe){
			setFloatingRecipe(value)
		}else if(value.startsWith("!!|")){
			foundFloatingStatus = value
		}
	}

    useEffect(_ => {
		if(floatingRecipe && foundFloatingStatus){
			// Merge together
			let foundFloatingRecipe = floatingRecipe.slice(3)
			foundFloatingStatus = foundFloatingStatus.slice(3)
			let newFilter = `${foundFloatingStatus}|${foundFloatingRecipe}`

			// Remove floating status & recipe & duplicates
			let removeFloated = innerFilter.filter(e => !(e.startsWith("??|") || e.startsWith("!!|") || e.split("|")[1] === foundFloatingRecipe))
			let newNewFilter = [...removeFloated, newFilter]

			setInnerFilter(newNewFilter)
			setFloatingRecipe(null)
		}

		let removeFloated = innerFilter.filter(e => !(e.startsWith("??|") || e.startsWith("!!|")))
		if(JSON.stringify(removeFloated) !== JSON.stringify(filter)){
			setFilter(removeFloated)
		}
    }, [innerFilter])

	function onDropdownClose(){
		setFloatingRecipe(null)
		setInnerFilter(innerFilter.filter(e => !(e.startsWith("??|") || e.startsWith("!!|"))))
	}

	let dataToShow;
	if(floatingRecipe && !foundFloatingStatus){
		dataToShow = statuses
	}else{
		dataToShow = latestRecipes.map(r => { return {value: `??|${r.name}|${r.command}`, label: r.name, group: r.command} })
		dataToShow.sort((a, b) => {
			if(a.group !== "postinstall" && b.group === "postinstall"){
				return -1
			}
			return 0
		})
	}

    return (<>
        <MultiSelect
			icon={<GoSettings/>}
            placeholder="Status filter"
            valueComponent={Value}
            searchable
            clearSearchOnChange
            itemComponent={Item}
            value={innerFilter}
            onChange={setInnerFilter}
			onDropdownClose={onDropdownClose}
            maxDropdownHeight={500}
            data={[...dataToShow, ...filter.map(i => { return {label: i, value: i, group: i} })]}
        />
    </>)
}
