import { Block } from '../Block/Block'
import { Popover, Text } from '@mantine/core';
import styles from './Server.module.scss'
import { useState, forwardRef } from 'react';

function sortRecipes(a, b){
    return a.name.localeCompare(b.name)
}

const InfoCircle = forwardRef((props, ref) => (
    <svg
        ref={ref}
        xmlns='http://www.w3.org/2000/svg'
        className='icon icon-tabler icon-tabler-info-circle'
        width={24}
        height={24}
        viewBox='0 0 24 24'
        stroke={"currentColor"}
        strokeWidth='2'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        {...props}
    >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <circle cx='12' cy='12' r='9' />
    <line x1='12' y1='8' x2='12.01' y2='8' />
    <polyline points='11 12 12 12 12 16 13 16' />
  </svg>
));

export function Server({status}){    
    const [opened, setOpened] = useState(false);
    const [clicked, setClicked] = useState(false);

    function open(doOpen = true, setClickedState = false){
        return () => {
            if(doOpen && setClickedState) setClicked(true)
            if(!doOpen && setClickedState) setClicked(false)
            if(!doOpen && clicked && !setClickedState) return

            setOpened(doOpen)
        }
    }

    let {audit, postinstall, run, transfer, latestMiddle, server} = status
    let runTemplate = status["run-template"]

    audit?.sort(sortRecipes)
    run?.sort(sortRecipes)
    postinstall?.sort(sortRecipes)

    transfer?.sort(sortRecipes)
    runTemplate?.sort(sortRecipes)

    // audit (        run            ) postinstall
    // OF
    // audit (transfer + run-template) postinstall

    function generateBlock(state, i){
        return <Block key={i} state={state} server={server}/>
    }

    function getRun(){
        if(latestMiddle.cmd === "run"){
            return <td>{run?.map(generateBlock)}</td>
        }else{
            return (<>
            <td>
                {transfer?.map(generateBlock)}
            </td>
            <td>
                {runTemplate?.map(generateBlock)}
            </td>
            </>)
        }
    }

    return (
        <>
        <tr className={styles.server}>
            <td className={styles.serverName}>
                {server.hostname}
                <Popover
                    className={styles.info}
                    opened={opened}
                    onClose={open(false, true)}
                    onMouseEnter={open(true)}
                    onMouseLeave={open(false)}
                    position="bottom"
                    placement="center"
                    withArrow
                    radius="10px"
                    trapFocus={false}
                    closeOnEscape={true}
                    transition="pop-top-left"
                >
                    <Popover.Target>
                        <InfoCircle onClick={open(true, true)} />
                    </Popover.Target>
                    <Popover.Dropdown>
                        <div style={{ color: "#a6a7ab" }}>
                            <Text><b>Host</b></Text>
                            <Text>{server.hostname}</Text>
                            <Text><b>IP</b></Text>
                            <Text>{server.ipaddr}</Text>
                            <Text><b>MAC</b></Text>
                            <Text>{server.macaddr}</Text>
                            <Text><b>Machine ID</b></Text>
                            <Text>{server.machineid}</Text>
                        </div>
                    </Popover.Dropdown>
                </Popover>
            </td>
            <td className={styles.blocks}>
                {audit?.map(generateBlock)}
            </td>
            <td className={styles.blocks}>
                {getRun()}
            </td>
            <td className={styles.blocks}>
                {postinstall?.map(generateBlock)}
            </td>
        </tr>
        </>
    )
}