import { forwardRef } from 'react';
import styles from './SimpleBlock.module.scss'
import blockStyles from '../Block/Block.module.scss'

export const blockStateValues = ["green", "green-grey", "yellow", "yellow-grey", "orange", "orange-grey", "red", "red-grey", "blinking", "empty"]

export function getBlock(value){
    if(!blockStateValues.includes(value)){
        return
    }

    return <SimpleBlock customClass={blockStyles[value]} />
}

export const SimpleBlock = forwardRef(({customClass, onClick, style, onMouseEnter = () => {}, onMouseLeave = () => {}}, ref) => {
    return (
        <div
            ref={ref}
            onClick={onClick}
            style={style}
            className={styles.block + " " + customClass}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        />
    )
})