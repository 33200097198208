import './App.css';
import Home from './pages/Home/Home';
import {MantineProvider, ColorSchemeProvider} from '@mantine/core';

import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import {useState} from 'react';

function App() {
    const [colorScheme, setColorScheme] = useState('dark');
    const toggleColorScheme = (value) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider
                theme={{
                    colors: {
                        "customGreen": ["#28CC9B", "#28CC9B", "#28CC9B", "#28CC9B", "#28CC9B", "#28CC9B", "#28CC9B", "#28CC9B", "#28CC9B", "#28CC9B"]
                    },
                    colorScheme: 'dark',
                }}
            >
                {false && <Router>
					<Routes>
						<Route exact path="/" element={<Home/>}/>
					</Routes>
				</Router>}
                <Home/>
            </MantineProvider>
        </ColorSchemeProvider>
    );
}

export default App;