import { useEffect, useState } from 'react';
import {Modal, Button, Group, TextInput, PasswordInput, Text, LoadingOverlay, Title} from '@mantine/core';
import { PersonIcon, LockClosedIcon } from '@radix-ui/react-icons';

export function LoginModal({setAuth}) {
    const [loading, setLoading] = useState(false);
    const [opened, setOpened] = useState(true);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [authError, setAuthError] = useState(null)

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true)

        let {success, token, expiresIn, error} = await fetch("/p78_api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username, password
            })
        }).then(r => r.json())

        if(success){
            setAuth(token)
            setOpened(false)

            setTimeout(() => {
                setPassword("")
                setAuth(false)
                setOpened(true)
                setAuthError("Login expired, please login again")
            }, expiresIn * 1000)
        }else{
            setAuth(false)
            setAuthError(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        let open = authError !== ""
        setOpened(open)
        setLoading(!open)
    }, [authError])

    return (
        <>
        <Modal
            onClose={() => {}}
            withCloseButton={false}
            opened={opened}
        >
            <Group position="center" mt="xl" mb={"xl"} pb={"xl"}>
                <img alt={"Project78 OnPrem"} width={"80%"} src="/p78_api/logo.svg" />
            </Group>
            <form onSubmit={handleSubmit}>
                <LoadingOverlay visible={loading} />

                <TextInput
                    mt="md"
                    size={"lg"}
                    required
                    autoFocus={true}
                    placeholder="Username"
                    label="Username"
                    icon={<PersonIcon />}
                    onChange={e => setUsername(e.target.value)}
                />

                <PasswordInput
                    mt="md"
                    size={"lg"}
                    required
                    placeholder="Password"
                    label="Password"
                    icon={<LockClosedIcon />}
                    onChange={e => setPassword(e.target.value)}
                />

                {authError && (
                    <Text color="red" size="sm" mt="sm">
                        {authError}
                    </Text>
                )}

                <Group position="center" mt="xl">
                    <Button size={"lg"} color="blue" type="submit" style={{fontSize: "1em"}}>
                        LOGIN
                    </Button>
                </Group>
            </form>
        </Modal>
        </>
    );
}
