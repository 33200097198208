import {Server} from '../Server/Server'
import styles from './ServerList.module.scss'
import {TextInput, Table, Pagination, Select, ActionIcon} from '@mantine/core'
import {Component, useEffect, useState} from 'react';
import {StateFilter} from '../StateFilter/StateFilter';
import {useDebouncedValue} from '@mantine/hooks';
import {GoSearch} from 'react-icons/go';
import {BiSortAlt2} from "react-icons/bi";
import {FaHashtag, FaFileCsv} from "react-icons/fa";
import {NewServerButton} from "../NewServerButton/NewServerButton";
import {DownloadCsvButton} from "../DownloadCsvButton/NewServerButton";

export const commands = ["audit", "run", "transfer", "run-template", "postinstall"]


function SearchField({onChange}) {
    const [innerValue, setInnerValue] = useState('');
    const [debounced] = useDebouncedValue(innerValue, 500);

    useEffect(() => {
        onChange(innerValue)
    }, [onChange, innerValue, debounced])

    return <>
        <TextInput
            icon={<GoSearch/>}
            placeholder="Search"
            value={innerValue}
            onChange={(event) => setInnerValue(event.currentTarget.value)}
        />
    </>
}

export class ServerList extends Component<{ authorization, license, setLicense }> {
    init: boolean
    uiChanging: boolean
    authorization: string
    license
    setLicense
    serverTimer
    recipeTimer

    constructor({authorization, license, setLicense}) {
        super({authorization, license, setLicense});
        this.license = license
        this.setLicense = setLicense
        this.authorization = authorization
        this.init = false
        this.uiChanging = false
        this.serverTimer = null
        this.recipeTimer = null
        this.state = {
            firstPull: true,
            increment: 0,
            maxPageSize: "100",
            totalServers: 0,
            servers: "[]",
            search: "",
            activePage: 1,
            filter: [],
            sortFunction: 'time-a-z',
        }
    }

    componentDidMount() {
        if (this.init) {
            return
        }

        this.updateServers().then()
        this.init = true;
    }

    componentWillUnmount() {
        clearTimeout(this.serverTimer);
    }

    shouldComponentUpdate(nextProps, nextState) {
        let state = this.state as any
        let shouldUpdate = state.increment !== nextState.increment
        let UIChanged = nextState.activePage !== state.activePage ||
            nextState.filter !== state.filter ||
            nextState.sortFunction !== state.sortFunction ||
            nextState.search !== state.search ||
            nextState.maxPageSize !== state.maxPageSize

        if (UIChanged && !this.uiChanging) {
            this.uiChanging = true
            this.updateServers(true, nextState).then(_ => {
                this.uiChanging = false
            });
        }

        return shouldUpdate
    }

    componentDidUpdate(prevProps, prevState) {
    }

    async updateServers(UIChanged = false, nextState = {}) {
        clearTimeout(this.serverTimer);

        try {
            await this._updateServers(UIChanged, nextState)
        } catch (e: any) {
            console.error(e)
        }

        console.log("Scheduled updateServers")
        this.serverTimer = setTimeout(this.updateServers.bind(this), 5000)
    }

    transformFilter(filter) {
        return filter.map(f => {
            const [action, name, command] = f.split("|")
            return {action, name, command}
        })
    }

    async _updateServers(UIChanged = false, nextState = {}) {
        let {
            firstPull,
            maxPageSize,
            servers,
            activePage,
            filter,
            sortFunction,
            search,
            increment
        } = (UIChanged ? nextState : this.state) as any;

        console.log("START Fetching state")
        console.time("fetch")
        let result = await fetch("/p78_api/state", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: this.authorization
            },
            body: JSON.stringify({
                hostname: search,
                states: this.transformFilter(filter),
                sort: sortFunction,
                amount: parseInt(maxPageSize),
                offset: (activePage - 1) * parseInt(maxPageSize)
            })
        }).then(r => r.json())
        console.timeEnd("fetch")
        console.log("END Fetching state")

        let newString = JSON.stringify(result.servers)
        if (!firstPull && !UIChanged && newString === servers) {
            return
        }

        this.setLicense(result.license)
        this.license = result.license
        this.setState({
            firstPull: false,
            servers: newString,
            totalServers: result.total,
            increment: increment + 1
        })
    }

    render() {
        const {
            maxPageSize,
            totalServers,
            servers,
            filter,
            sortFunction,
            search,
        } = this.state as any;

        let totalPages = Math.ceil((totalServers || 0) / maxPageSize)

        return (
            <>
                <div style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    justifyContent: "space-around",
                    borderRadius: "6px",
                    backgroundColor: "#0A202F",
                    border: "1px solid #183B53",
                    margin: "0 2em",
                    padding: "1em 1em",
                }}>
                    <NewServerButton license={this.license}/>
                    <SearchField
                        onChange={search => this.setState({search})}
                    />
                    <StateFilter
                        authorization={this.authorization}
                        filter={filter}
                        setFilter={filter => this.setState({filter})}
                    />
                    <Select
                        icon={<BiSortAlt2/>}
                        placeholder="Sort by"
                        value={sortFunction}
                        onChange={sortFunction => this.setState({sortFunction})}
                        data={[
                            {value: 'time-a-z', label: 'Newest First'},
                            {value: 'time-z-a', label: 'Oldest First'},
                            {value: 'name-a-z', label: 'Name A-Z'},
                            {value: 'name-z-a', label: 'Name Z-A'},
                            {value: 'error-a-z', label: 'Most errors'},
                            {value: 'error-z-a', label: 'Least errors'},
                        ]}
                    />
                    <div style={{display: "flex", gap: "10px"}}>
                        <Pagination ml={10} onChange={activePage => this.setState({activePage})} total={totalPages}
                                    size="xs"/>
                        <span style={{lineHeight: 2, color: "#C1C2C5"}}>({totalServers})</span>
                    </div>
                    <Select
                        icon={<FaHashtag/>}
                        placeholder="Page size"
                        maw={"15ch"}
                        value={maxPageSize}
                        defaultValue={maxPageSize}
                        onChange={maxPageSize => this.setState({maxPageSize})}
                        data={[
                            {value: '10', label: '10'},
                            {value: '25', label: '25'},
                            {value: '50', label: '50'},
                            {value: '75', label: '75'},
                            {value: '100', label: '100'},
                            {value: '150', label: '150'},
                        ]}
                    />
                    <DownloadCsvButton
                        authorization={this.authorization}
                        hostname={search}
                        states={this.transformFilter(filter)}
                        sort={sortFunction}
                    />
                </div>
                <Table className={styles.table} withColumnBorders>
                    <thead>
                    <tr>
                        <th>Host</th>
                        <th>Audit</th>
                        <th>Run</th>
                        <th>Post-Install</th>
                    </tr>
                    </thead>
                    <tbody>
                    {JSON.parse(servers).map(s => <Server key={s.server.id} status={s}/>)}
                    </tbody>
                </Table>
            </>
        );
    }
}
