import { ServerList } from '../../modules/ServerList/ServerList'
import { LoginModal } from '../../modules/LoginModal/LoginModal'
import {AppShell, Code, Header, Modal} from '@mantine/core';
import { useState, useEffect } from 'react';

export function LicenseView({license}){
    let {max, current, company, valid, totalAudited, totalUpgraded} = license as any || {}

    return <>
        {max && <p style={{color: valid ? "#909090" : "#ff0000", textAlign: "right"}}>
            <b>{company}</b>
            <br/>
            <span style={{marginRight: "10px"}}>
                <b>Audited</b>: {current}
            </span>
            <span style={{marginRight: "10px"}}>
                <b>Upgraded</b>: {totalUpgraded}
            </span>
            <span>
                <b>Entitlements</b>: {max}
            </span>
        </p>}
    </>
}

export default function Home(){
    const [auth, setAuth] = useState(false)
    const [license, setLicense] = useState({})
    const [globalErrors, setGlobalErrors] = useState([])

    function getGlobalErrors(){
        fetch("/p78_api/globalerrors", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then(r => r.json()).then(r => {
            setGlobalErrors(r)
        })
    }

    useEffect(() => {
        getGlobalErrors()
        const interval = setInterval(() => {
            getGlobalErrors()
        }, 15000)
        return () => clearInterval(interval)
    }, [])

    return (
        <>
        <AppShell>
            <Header height={70} p="md" style={{border: "none", backgroundColor: "transparent", position: "absolute"}}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", height: '100%' }}>
                    <img height={45} src={"/p78_api/logo2.svg"} alt={"Project78 OnPrem"} />
                    {auth && <LicenseView license={license} />}
                </div>
            </Header>
            <LoginModal setAuth={setAuth}/>

            {globalErrors.length > 0 && <Modal onClose={() => {
            }} opened={true} withCloseButton={false}>
                Critical Error(s) Occurred:
                <ul>
                    {globalErrors.map((e, i) => <li key={i}><Code>{e}</Code></li>)}
                </ul>

                <br/>
                <button onClick={() => {
                    fetch("/p78_api/globalerrors", {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                        }
                    }).then(r => r.json()).then(r => {
                        setGlobalErrors(r)
                    })
                }}>Clear Errors
                </button>
            </Modal>}

            {auth && <ServerList license={license} setLicense={setLicense} authorization={auth}/>}
        </AppShell>
        </>
    )
}