import {useEffect, useState} from 'react';
import {Modal, ActionIcon, Tooltip, Code, Select, Popover, Button, Text, Box} from '@mantine/core';
import {FaFileDownload} from "react-icons/fa";

export function DownloadCsvButton({authorization, hostname, states, sort}) {
    const [isLoading, setIsLoading] = useState(false);

    async function downloadCsv(withData){
        setIsLoading(true)
        let response = await fetch("/p78_api/export", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization
            },
            body: JSON.stringify({
                hostname, states, sort, withData
            })
        })

        const header = response.headers.get('Content-Disposition');
        let fileName = header.split(/;(.+)/)[1].split(/=(.+)/)[1] || "export.csv";
        if (fileName.toLowerCase().startsWith("utf-8''")){
            fileName = decodeURIComponent(fileName.replace(/utf-8''/i, ''));
        }else{
            fileName = fileName.replace(/['"]/g, '');
        }

        if(fileName.startsWith("_") && fileName.endsWith("_") && fileName.length > 2){
            fileName = fileName.substring(1, fileName.length - 1)
        }

        const blob = await response.blob()


        let a = document.createElement('a');

        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;

        document.body.appendChild(a);

        a.click();
        a.remove();
        setIsLoading(false)
    }

    return (
        <div>
            {isLoading &&
                <ActionIcon loading={isLoading} h={"36px"} w={"36px"} variant="outline">
                    <FaFileDownload/>
                </ActionIcon>
            }
            {!isLoading && <Popover width={250} trapFocus position="bottom" withArrow shadow="md" disabled={isLoading}>
                <Popover.Target>
                    <ActionIcon loading={isLoading} h={"36px"} w={"36px"} variant="outline">
                        <FaFileDownload/>
                    </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown
                    sx={(theme) => ({background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white})}
                    style={{textAlign: "center"}}>
                    <Text color={"rgb(193, 194, 197)"}>Export Data</Text>
                    <br/>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-around"
                    }}>
                        <Button onClick={_ => downloadCsv(false)}>Status only</Button>
                        <Button onClick={_ => downloadCsv(true)}>Full (large)</Button>
                    </Box>
                </Popover.Dropdown>
            </Popover>}
        </div>
    );
}
