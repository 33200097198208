import { useState } from 'react';
import styles from './Block.module.scss'
import { SimpleBlock } from '../SimpleBlock/SimpleBlock';
import { Popover, Text, Code, Tooltip, ActionIcon } from '@mantine/core';
import { CopyIcon } from './CopyIcon'
import { useClipboard } from '@mantine/hooks';

function getClipboardData(customClassName, state, server){
    //{ "ipaddr": "192.168.1.88", "hostname": "dns002", "macaddr": "5254002dec2c", "machineid": "6cc5a497895341778946bd076229f429", "command": "audit", "recipename": "80_leapp_preupgrade", "version": 6, "action": "ERROR", "data": "<LOG>", "datetime": "2022-03-26T20:31:12.000Z", "latestversion": 6 }
    return `IP: ${server.ipaddr}\n` + 
            `Host: ${server.hostname}\n` + 
            `Date: ${state.updatedAt}\n` + 
            `Command: ${state.command}\n` + 
            `Recipe: ${state.name} v${state.version} (latest: v${state.latestVersion})\n` + 
            `State: ${customClassName} (${state.action})\n` + 
            `LOG:\n${state.data}`
}

export function Block({state, server}){
    const [opened, setOpened] = useState(false);
    const [clicked, setClicked] = useState(false);
    const clipboard = useClipboard();
    let date = new Date(state.updatedAt)
    let dateTimeStr = date.toLocaleTimeString() + " " + date.toDateString()
    let customClassName = state.state
    let customClass = styles[customClassName]

    function open(doOpen = true, setClickedState = false){
        return () => {
            if(doOpen && setClickedState) setClicked(true)
            if(!doOpen && setClickedState) setClicked(false)
            if(!doOpen && clicked && !setClickedState) return

            setOpened(doOpen)
        }
    }

    return (
        <div style={{position: "relative", display: "inline-block"}}>
            <SimpleBlock
                onMouseEnter={open(true)}
                onMouseLeave={open(false)}
                onClick={open(true, true)}
                customClass={customClass}
            />
            {opened && <div style={{position: "absolute", left: "7px", top: "3px"}}>
            <Popover
                opened={opened}
                onClose={open(false, true)}
                position="bottom"
                placement="center"
                withArrow
                radius="10px"
                trapFocus={true}
                closeOnEscape={true}
                transition="pop-top-left"
            >
                <Popover.Target>
                    <div></div>
                </Popover.Target>
                <Popover.Dropdown>
                    <div style={{color: "#a6a7ab"}}>
                        <div style={{fontWeight: "bold"}}>
                            <Text className={styles.txt}>{state.name}</Text>
                            <SimpleBlock style={{marginLeft: "10px"}} customClass={customClass}/>
                            <Text className={styles.txtRight}>{server.hostname}</Text>
                        </div>
                        <div>
                            <Text className={styles.txt}>{dateTimeStr}</Text>
                            <Text className={styles.txtRight}>{server.ipaddr}</Text>
                        </div>
                        <Tooltip
                            className={styles.copy}
                            label={clipboard.copied ? 'Copied' : 'Copy Code'}
                            position="left"
                            placement="center"
                            transition="fade"
                            withArrow
                            arrowSize={4}
                            gutter={8}
                            color={clipboard.copied ? 'teal' : undefined}
                        >
                            <ActionIcon
                                aria-label={clipboard.copied ? 'Copied' : 'Copy Code'}
                                onClick={() => clipboard.copy(getClipboardData(customClassName, state, server))}
                            >
                                <CopyIcon copied={clipboard.copied}/>
                            </ActionIcon>
                        </Tooltip>
                        {state.data && (
                            <Code className={styles.code} block style={{maxWidth: "1000px"}}>
                                {state.data}
                            </Code>)}
                        <div style={{clear: "both"}}></div>
                    </div>
                </Popover.Dropdown>
            </Popover>
        </div>}
    </div>
    )
}